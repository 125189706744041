import React from "react"
import { CenterContent, SubAndHeadline } from "../components/_index"
import { Grid } from "@material-ui/core"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Typography from "@material-ui/core/Typography"
import RichTextRenderer from "../components/RichTextRenderer"
import SiteMetadata from "../components/SiteMetaData"
import Layout from "../components/Layout"

const IndexPage = ({ data }) => {
  const {
    content: {
      title,
      subtitle,
      childContentfulInfoDescriptionRichTextNode: { json: richText },
    },
  } = { ...data }

  return (
    <Layout>
      <SiteMetadata description={subtitle} />
      <CenterContent>
        <Grid container direction="column" spacing={3} align={"center"}>
          <SubAndHeadline title={title} subtitle={subtitle} />
          <Grid container item justifyContent="center">
            <Grid item xs={10} sm={8} md={8}>
              <Typography
                component={"span"}
                variant={"body1"}
                style={{ wordBreak: "break-word" }}
                gutterBottom
              >
                <RichTextRenderer richTextJson={richText} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CenterContent>
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object,
}

export const query = graphql`
  query {
    content: contentfulInfo {
      title
      subtitle
      childContentfulInfoDescriptionRichTextNode {
        json
      }
    }
  }
`

export default IndexPage
